let ageModal = document.querySelector('[data-name="age_check"]');

if (ageModal) {
    ageModal.querySelector('.age_check__confirm').onclick = function() {
        ageModal.style.display = 'none';
        let date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        document.cookie = 'age=18; path=/; expires=' + date.toUTCString();
    };
}
