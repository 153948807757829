/**
 *
 * @type {jQuery}
 */
const $filter = $('.filter');

const init = () => {
    $filter.children('.filter__header').on('click', function() {
        if (window.innerWidth <= 991) {
            $filter.toggleClass('filter--opened');

            $(this)
                .next()
                .slideToggle(500);
        }
    });
};

export { init, $filter };
