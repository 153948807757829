import TurboForm from '../lib/TurboForm';
import '../lib/jquery.ui.widget';

const forms = document.querySelectorAll('.js-cart-form');
if (forms.length > 0) {
    forms.forEach(function(el) {
        const form = new TurboForm(el, {
            errorClass: 'form__group--error',
            fieldLineClass: 'form__group',
            errorMsgClass: 'form__error_message',
            showErrorAfterInput: true
        });

        const wrapper = form.form.closest('.form_wrapper');
        const submitButton = form.form.querySelector('.form__send');
        const successMessage = form.form.closest('.order__form').querySelector('.success--light');
        const successAccept = successMessage.querySelector('.success__accept');

        if (successAccept) {
            successAccept.addEventListener('click', () => {
                var formName = form.form.getAttribute('name');
                if (form.form.closest('.modal__content')) {
                    $(`[data-name="${formName}"]`).fadeOut(700, () => {
                        wrapper.style.display = 'block';
                        successMessage.style.display = 'none';
                    });
                } else {
                    $(successMessage).fadeOut(700, () => {
                        wrapper.style.display = 'block';
                    });
                }
            });
        }

        form.validate = function() {
            TurboForm.prototype.validate.apply(this);

            if (form.isValidate) {
                submitButton.classList.remove('btn--disabled');
            } else {
                submitButton.classList.add('btn--disabled');
            }
        };

        // form.form.addEventListener('input', function() {
        //     form.validate();
        // });

        submitButton.addEventListener('click', (e) => {
            e.preventDefault();
            form.hideOldErrors();
            form.onSubmit();

            if (successMessage.classList.contains('sending_results--visible')) {
                successMessage.classList.remove('sending_results--visible');
                successMessage.classList.add('sending_results--hidden');
            }
        });

        form.showPreloader = function() {
            if (wrapper) {
                wrapper.classList.add('form_wrapper--sending');
            }
        };

        form.hidePreloader = function() {
            if (wrapper) {
                wrapper.classList.remove('form_wrapper--sending');
            }
        };

        form.reset = function() {
            TurboForm.prototype.reset.apply(this);
        };

        form.showSuccessMessage = function(msg) {
            window.location.href = msg;

            this.reset();
        };

        form.showErrorForInput = function(container, input, filedName) {
            TurboForm.prototype.showErrorForInput.apply(this, [container, input, filedName]);
            container.classList.add('form__group--error');
        };

        form.hideOldErrors = function() {
            TurboForm.prototype.hideOldErrors.apply(this);
            var items = this.form.querySelectorAll('.form__group--error');
            if (items.length) {
                items.forEach(function(field) {
                    field.classList.remove('form__group--error');
                });
            }
        };

        form.validate();
    });
}
