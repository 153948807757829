// Будем вешать здесь подписки на скролл и ресайз, которые можно вынести сюда
import throttle from 'lodash.throttle';
import { $filter } from './Filter';
import { libNodes, getScrollbarWidth, customEvents } from '../utils/Utils';

let count = 0;

if (document.querySelector('[data-name="age_check"]')) {
    count = 1;
}

const init = () => {
    libNodes.$html.css({
        '--scrollbar_width': `${getScrollbarWidth()}px`
    });

    window.addEventListener(
        'resize',
        throttle(() => {
            if (window.innerWidth > 991) {
                $filter
                    .removeClass('filter--opened')
                    .children('.filter__toggle_content')
                    .removeAttr('style');

                if (libNodes.$header.children('.header__desktop').attr('style')) {
                    libNodes.$header.children('.header__desktop').removeAttr('style');

                    window.dispatchEvent(customEvents.modalclosed);
                }

                if (libNodes.$html.hasClass('modal_opened') && count === 0) {
                    libNodes.$html.removeClass('modal_opened');
                }
            } else {
                if (libNodes.$header.hasClass('header--flow')) {
                    libNodes.$header
                        .removeClass('header--flow')
                        .css({
                            'margin-bottom': ''
                        })
                        .find('.header__bottom')
                        .removeClass('header__bottom--fixed');
                }
            }

            libNodes.$html.css({
                '--header_height': `${libNodes.$header.outerHeight()}px`
            });
        }, 200)
    );

    window.addEventListener('modalopened', () => {
        libNodes.$html.addClass('modal_opened');

        count++;
    });

    window.addEventListener('modalclosed', () => {
        count--;

        if (count === 0) {
            libNodes.$html.removeClass('modal_opened');
        }
    });
};

export default init();
