import { customEvents, libNodes, keyEvents } from '../utils/Utils';

const $fadeInBtn = $('.js-fade-in');
const $fadeOutBtn = $('.js-fade-out');

/**
 *
 * @param {jQuery} $element Элемент, который необходимо показать
 * @param {Function} callback Функция, которая выполнится после появления
 */
const fadeIn = ($element, callback) => {
    if (typeof callback === 'function') {
        $element.fadeIn(500, callback);
    } else {
        $element.fadeIn(500);
    }

    libNodes.$document.on('keydown.modal', (e) => {
        if (keyEvents.isEscape(e.keyCode)) {
            fadeOut($element);
        }
    });

    window.dispatchEvent(customEvents.modalopened);
};

/**
 *
 * @param {jQuery} $element Элемент, который необходимо скрыть
 * @param {Function} callback Функция, которая выполнится после исчезновения
 */
const fadeOut = ($element, callback) => {
    if (typeof callback === 'function') {
        $element.fadeOut(500, callback);
    } else {
        $element.fadeOut(500);
    }

    libNodes.$document.off('keydown.modal');

    window.dispatchEvent(customEvents.modalclosed);
};

const init = () => {
    $fadeInBtn.on('click', function(e) {
        e.preventDefault();

        fadeIn($(`[data-name="${this.dataset.target}"]`));
    });

    $fadeOutBtn.on('click', function(e) {
        e.preventDefault();

        fadeOut($(`[data-name="${this.dataset.target}"]`));
    });
};

export { init, fadeIn, fadeOut };
