import throttle from 'lodash.throttle';
import { scrollToElement, libNodes } from '../utils/Utils';

const $layout = $('.layout');
const $scrollTop = $('.to_top');
let triggerHeight = window.innerHeight * 2;

const onScrollTopClick = (e) => {
    e.preventDefault();

    scrollToElement(document.querySelector(`${$scrollTop[0].hash}`));
};

const onWindowScroll = throttle(() => {
    const scrollTop = libNodes.$window.scrollTop();

    if (scrollTop > triggerHeight) {
        $scrollTop.addClass('to_top--visible');
    } else {
        $scrollTop.removeClass('to_top--visible');
    }
}, 150);

const onWindowResize = throttle(() => {
    if ($layout.offset().left < 90) {
        $scrollTop.addClass('to_top--simple').css({
            width: ''
        });
    } else {
        $scrollTop.removeClass('to_top--simple').css({
            width: `${$layout.offset().left}px`
        });
    }
}, 200);

const init = () => {
    $scrollTop.on('click', onScrollTopClick);

    libNodes.$window.on('scroll', onWindowScroll);
    libNodes.$window.on('resize', onWindowResize);

    onWindowScroll();
    onWindowResize();
};

export default init();
