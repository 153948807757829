import throttle from 'lodash.throttle';
import { isMobileSystem } from '../utils/Utils';

const $slider = $('.js-slider');
const $sliderMain = $('.js-slider-main');

class Slider {
    constructor($element, options) {
        this.$el = $element;
        this.options = options || {};
        this.init();
    }

    init() {
        this.$track = this.$el.children('.slider__track');
        this.$slides = this.$el.find('.slider__slide');
        this.$slidesWrapper = this.$track.children('.slider__slides');
        this.slideWidth = $(this.$slides[0]).width();
        this.$dots = this.$el.find('.slider__dots');
        this.length = this.$slides.length;
        this.fullWidth = this.length * this.slideWidth;
        this.currentSlide = 0;
        this.previousSlide = this.currentSlide;
        this.autoplay = this.options.autoplay === true;
        this.autoplaySpeed = 8000;
        this.autoplayId = null;
        this.animated = false;

        if (isMobileSystem()) {
            this.$el.addClass('slider--mobile');
        }

        this.updateSlider();

        this.$slides[this.currentSlide].classList.add('slider__slide--active');

        if (this.autoplay) {
            this.initAutoplay();
        }

        this.prevBtn = this.$el.find('.slider__prev');
        this.nextBtn = this.$el.find('.slider__next');

        this.prevBtn.on('click', () => {
            if (!this.animated) {
                this.prev();
            }
        });

        this.nextBtn.on('click', () => {
            if (!this.animated) {
                this.next();
            }
        });

        this.$dots.on('click', '.slider__dot', (e) => {
            if (!this.animated) {
                this.previousSlide = this.currentSlide;
                this.currentSlide = e.target.dataset.slide;
                this.changeSlide(e.target.dataset.slide);
            }
        });

        for (let i = 0; i < this.length; i++) {
            this.$dots.append(this.renderDot(i));
        }

        window.addEventListener(
            'resize',
            throttle(() => {
                this.slideWidth = this.$el.width();

                this.updateSlider();

                this.$slidesWrapper.css({
                    transform: `translate3d(${this.slideWidth * this.currentSlide * -1}px, 0, 0)`
                });
            }, 200)
        );
    }

    changeSlide(number) {
        this.animated = true;

        clearInterval(this.autoplayId);

        $(this.$slides[this.previousSlide]).removeClass('slider__slide--active');

        this.$slidesWrapper
            .css({
                transform: `translate3d(${this.slideWidth * number * -1}px, 0, 0)`
            })
            .on('transitionend', (e) => {
                if (e.target.classList.contains('slider__slides')) {
                    $(this.$slides[number]).addClass('slider__slide--active');
                    this.$slidesWrapper.off('transitionend');
                    this.initAutoplay();
                    this.animated = false;
                }
            });

        this.updateDots();
    }

    initAutoplay() {
        this.autoplayId = setInterval(() => {
            this.next();
        }, this.autoplaySpeed);
    }

    updateSlider() {
        this.$track.css({
            width: `${this.slideWidth}px`
        });

        $(this.$slides).css({
            width: `${this.slideWidth}px`
        });

        this.fullWidth = this.length * this.slideWidth;

        this.$slidesWrapper.css({
            width: `${this.fullWidth}px`
        });
    }

    updateDots() {
        this.$dots
            .children(`[data-slide="${this.currentSlide}"]`)
            .addClass('slider__dot--current')
            .siblings()
            .removeClass('slider__dot--current');
    }

    prev() {
        this.previousSlide = this.currentSlide;
        this.currentSlide--;

        if (this.currentSlide < 0) {
            this.currentSlide = this.length - 1;
        }

        this.changeSlide(this.currentSlide);
    }

    next() {
        this.previousSlide = this.currentSlide;
        this.currentSlide++;

        if (this.currentSlide === this.length) {
            this.currentSlide = 0;
        }

        this.changeSlide(this.currentSlide);
    }

    renderDot(id) {
        return `
            <li class="slider__dot ${this.currentSlide === id ? 'slider__dot--current' : ''}" data-slide="${id}">
        `;
    }
}

const init = () => {
    if ($slider.length) {
        const slider = new Slider($slider);
    }

    if ($sliderMain.length) {
        const sliderMain = new Slider($sliderMain, {
            autoplay: true
        });
    }
};

export default init();
