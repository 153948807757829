import 'lightgallery';
import 'lightgallery/modules/lg-thumbnail';
import 'lightgallery/modules/lg-zoom';
import 'lightgallery/modules/lg-fullscreen';

const $gallery = $('.js-gallery');

const init = () => {
    $gallery.lightGallery({
        selector: '.gallery__item, .slider__cover',
        download: false,
        thumbnail: true,
        fullscreen: true,
        zoom: true
    });
};

export default init();
