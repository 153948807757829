import './modules/OnInitPage';
import './modules/Text';
import './modules/Gallery';
import './modules/Slider';
import './modules/Select';
import { init as fadeInOutInit } from './modules/FadeInOut';
import './modules/Inputs';
import './modules/Map';
import './modules/ScrollTop';
import './modules/Header';
import './modules/Products';
import { init as filterInit } from './modules/Filter';
import './modules/Fox';
import './modules/Counter';
import './modules/CartForm';
import './modules/Forms';
import './modules/Age';
import './modules/CartInterface';
import './modules/Catalog';
fadeInOutInit();
filterInit();

$('.js-filter-rubricator')
    .find('select')
    .on('change', function() {
        $(this)
            .closest('.js-filter-rubricator')
            .submit();
    });
