var $filterForm = $('form.js-catalog-filter');
if ($filterForm) {
    var $submitButton = $filterForm.find('.filter__apply'),
        $inputs = $filterForm.find('input'),
        timeout = null;
    $inputs.on('change', function(e) {
        e.preventDefault();

        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(function() {
            var name = $(e.target)
                .closest('.filter-prod__item')
                .data('name');
            $.post(window.location.pathname + '?' + $filterForm.serialize(), function(resp) {
                for (var respKey in resp) {
                    if (respKey !== name) {
                        $filterForm.find('[data-name=' + respKey + '] input').map(function(i, input) {
                            if (!input.checked) {
                                input.disabled = resp[respKey].indexOf(input.value) === -1;
                            }
                        });
                    }
                }
            });
        }, 800);
    });
    $filterForm.on('submit', function(e) {
        e.preventDefault();
        $submitButton.addClass('btn--disabled');
        window.location.href = window.location.pathname + '?' + $(e.target).serialize();
    });

    $filterForm.find('.filter__reset').on('click', function(e) {
        window.location = this.dataset.link;
    });

    $('.js-catalog-sort')
        .find('select')
        .on('change', function(e) {
            if (this.value) {
                window.location = this.value;
            }
            // this.submit();
        });
}
