import throttle from 'lodash.throttle';
import { libNodes } from '../utils/Utils';

const $headerBottom = libNodes.$header.find('.header__bottom');
let headerBottomHeight = $headerBottom.outerHeight();
let headerBottomOffset = $headerBottom.offset().top;

const onScroll = throttle(() => {
    if (window.innerWidth > 991) {
        if (libNodes.$window.scrollTop() >= headerBottomOffset) {
            $headerBottom.addClass('header__bottom--fixed');

            libNodes.$header.addClass('header--flow').css({
                'margin-bottom': `${headerBottomHeight}px`
            });
        } else {
            $headerBottom.removeClass('header__bottom--fixed');

            libNodes.$header.removeClass('header--flow').css({
                'margin-bottom': ''
            });
        }

        libNodes.$html.css({
            '--sticky_aside_offset': `${$headerBottom.outerHeight()}px`
        });
    }
}, 150);

const onResize = throttle(() => {
    headerBottomHeight = $headerBottom.outerHeight();

    if (window.innerWidth > 991) {
        headerBottomOffset = $headerBottom.offset().top;
    }
}, 500);

const init = () => {
    libNodes.$window.on('scroll', onScroll);
    libNodes.$window.on('resize', onResize);

    // Запишем высоту шапки в css var
    libNodes.$html.css({
        '--header_height': `${libNodes.$header.outerHeight()}px`
    });

    onScroll();
};

export default init();
