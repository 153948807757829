/**
 * Функция определяет текущую мобильную операционную систему устройства
 *
 * @returns {String} Возвращает текущую операционную систему
 */
const getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
        return 'Android';
    }

    if (/ipad|iphone|ipod/i.test(userAgent)) {
        return 'iOS';
    }
};

export default getMobileOperatingSystem;
