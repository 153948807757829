const keyCodes = {
    enter: 13,
    escape: 27,
    arrowRight: 39,
    arrowLeft: 37
};

const keyEvents = {
    isEscape: (keyCode) => keyCode === keyCodes.escape
};

export default keyEvents;
