import throttle from 'lodash.throttle';
import { libNodes, isInViewPort } from '../utils/Utils';

const $fox = $('.js-fox');

const init = () => {
    if (libNodes.$window.outerWidth() > 991 && $fox.length) {
        libNodes.$window.on(
            'scroll.fox',
            throttle(() => {
                if (isInViewPort($fox[0])) {
                    $fox.addClass('about__fox--appear');

                    libNodes.$window.off('scroll.fox');
                }
            }, 150)
        );
    }
};

export default init();
