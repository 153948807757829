//Интерфейс для работы с корзиной
import Meta from '../lib/meta';
import Cart from '../lib/cart';
import { libNodes } from '../utils/Utils';

//обновления блока с информацией о корзине
var updateCartBlock = function() {
    var totalCount = 0,
        $cart = $('.js-cart');

    for (var i = 0; i < Cart.items.length; i++) {
        totalCount += parseInt(Cart.items[i].qty, 10);
    }

    if (totalCount > 0) {
        $cart
            .find('.cart__badge')
            .show()
            .text(totalCount > 99 ? 99 : totalCount);
    } else {
        $cart
            .find('.cart__badge')
            .hide()
            .text(0);
    }
};

//обновлялка инфы на странице корзины
var updateCartPage = function() {
    var totalPrice = 0;
    var totalQty = 0;

    for (var item_id in Cart.items) {
        totalPrice += Cart.items[item_id].price * Cart.items[item_id].qty;
    }
    for (var item_id in Cart.items) {
        totalQty += Cart.items[item_id].qty;
    }

    $('.js-cart-sum').text('Стоимость заказа: ' + Meta.beautyPrice(totalPrice) + ' ₽');
    $('.js-cart-count').text('Всего товаров в заказе: ' + totalQty);
};

$(function() {
    //кнопка купить
    libNodes.$body.on('click', '.js-cart-adding-accept', function(e) {
        var $this = $(this),
            id = parseInt($this.attr('data-id'), 10),
            $counterInput = $this
                .parent()
                .prev('.cart_adding__counter')
                .children('.js-counter-input'),
            item = Cart.getItem(id),
            itemqty = parseInt($counterInput.val());

        $counterInput.val(1);
        if (item) {
            Cart.putItem(
                {
                    id: id,
                    qty: item.qty + itemqty
                },
                true,
                function() {
                    updateCartBlock();
                }
            );
        } else {
            Cart.putItem(
                {
                    id: id,
                    qty: itemqty
                },
                false,
                function() {
                    updateCartBlock();
                }
            );
        }

        e.preventDefault();
    });

    // ************ страница корзины ***************

    $('.order__clear').on('click', function(e) {
        e.preventDefault();
        if (Cart.items.length > 0) {
            $('.js-cart-item').each(function() {
                $(this).slideUp(500, function() {
                    $(this).remove();
                });
            });
            $(this).hide();
            Cart.removeAll(function() {
                updateCartBlock();
                updateCartPage();
                window.location.href = $('.js-cart').attr('data-empty');
            });
        }
    });

    $('.js-cart-item').map(function() {
        var $product = $(this),
            id = parseInt($product.attr('data-id'), 10),
            price = parseInt($product.attr('data-price'), 10);

        $product.find('.order_card__remove').on('click', function(e) {
            if (Cart.items.length > 1) {
                $product.slideUp(500, function() {
                    $(this).remove();
                });
            } else {
                window.location.href = $('.js-cart').attr('data-empty');
            }

            Cart.removeItem({ id: id }, function() {
                updateCartBlock();
                updateCartPage();
            });

            e.preventDefault();
        });

        $product.find('.js-counter-increase').on('click', function(e) {
            var newValue =
                $product
                    .find('.js-counter-input')
                    .val()
                    .replace(/\,/, '.') * 1;
            newValue = newValue + 1;

            $product.find('.cost_group').text(Meta.beautyPrice(price * newValue) + ' ₽');

            Cart.putItem(
                {
                    id: id,
                    qty: newValue,
                    price: price
                },
                true
            );

            updateCartBlock();
            updateCartPage();

            e.preventDefault();
        });

        $product.find('.js-counter-decrease').on('click', function(e) {
            var newValue =
                $product
                    .find('.js-counter-input')
                    .val()
                    .replace(/\,/, '.') * 1;
            newValue = newValue - 1;
            $product.find('.cost_group').text(Meta.beautyPrice(price * newValue) + '\u00A0₽');

            Cart.putItem(
                {
                    id: id,
                    qty: newValue,
                    price: price
                },
                true
            );

            updateCartBlock();
            updateCartPage();

            e.preventDefault();
        });

        $product.find('.js-counter-input').on('input', function(e) {
            var newQty = parseInt($(this).val(), 10);

            if (!newQty || newQty < 0) {
                newQty = 1;
            }

            if (newQty > Cart.maxItemQty) {
                newQty = Cart.maxItemQty;
            }

            $(this).val(newQty);

            $product.find('.cost_group').text(Meta.beautyPrice(price * newQty) + ' ₽');

            Cart.putItem(
                {
                    id: id,
                    qty: newQty,
                    price: price
                },
                true
            );

            updateCartBlock();
            updateCartPage();

            e.preventDefault();
        });
    });

    Cart.onInitCallback = function() {
        updateCartBlock();
        updateCartPage();
    };
});
