const $maps = $('.js-map');

const initMap = () => {
    if ($maps.length) {
        function mapLoad() {
            ymaps
                .ready(() => {
                    $maps.each(function() {
                        let data = JSON.parse($(this).attr('data-json'));
                        let coords = [data.lat, data.lng];
                        let myMap = new ymaps.Map(
                                this,
                                {
                                    center: coords,
                                    zoom: data.zoom || 16,
                                    controls: [
                                        'zoomControl',
                                        'searchControl',
                                        'typeSelector',
                                        'fullscreenControl',
                                        'trafficControl',
                                        'geolocationControl'
                                    ]
                                },
                                {
                                    searchControlProvider: 'yandex#search'
                                }
                            ),
                            myPlacemark = new ymaps.Placemark(coords, {
                                balloonContent: data.title + '<br>' + data.content
                            });

                        myMap.controls.each(function(control) {
                            control.options.set('float', 'right');
                        });

                        myMap.geoObjects.add(myPlacemark);
                    });
                })
                .catch(function(err) {
                    console.error(err);
                });
        }

        if (!window.ymaps) {
            $.getScript(
                'https://api-maps.yandex.ru/2.1/?apikey=9504233c-f0ea-4436-b55e-9df482b5a0e2&lang=ru_RU',
                mapLoad
            );
        } else {
            mapLoad();
        }
    }
};

const init = () => {
    initMap();
};

export default init();
