import { customEvents, libNodes, keyEvents } from '../utils/Utils';
import { fadeIn, fadeOut } from './FadeInOut';

const $productModal = $('.product_modal');

const init = () => {
    libNodes.$body.on('click', '.js-open-product-modal', function(e) {
        e.preventDefault();
        const url = this.dataset.preview;
        $.post(url, function(resp) {
            if (resp.success) {
                $productModal
                    .find('.product_modal__box')
                    .html(resp.html)
                    .promise()
                    .done(function() {
                        $productModal.fadeIn(300);
                        window.dispatchEvent(customEvents.modalopened);
                    });
            }
        });
        // Может пригодится - я хз
    });

    libNodes.$body.on('click', '.js-change-product-modal', function(e) {
        e.preventDefault();
        const url = this.dataset.preview;
        $.post(url, function(resp) {
            if (resp.success) {
                $productModal.find('.product_modal__box').html(resp.html);
            }
        });
    });

    libNodes.$body.on('click', '.product_modal__close', function(e) {
        e.preventDefault();
        const $target = $(this).closest(`[data-name="${this.dataset.target}"]`);
        fadeOut($target);
    });
};

// Всплывашка при нажатии на "В корзину"
libNodes.$body.on('click', '.js-cart-adding', function(e) {
    e.preventDefault();

    const $this = $(this);
    const $popover = $this.siblings('.js-cart-adding-popover');
    const $close = $popover.children('.js-cart-adding-close');
    const $wrapper = $popover.parent();

    const $accept = $popover.find('.js-cart-adding-accept');
    const $stepOne = $popover.children('.js-cart-adding-step-one');
    const $stepTwo = $popover.children('.js-cart-adding-step-two');

    const $product = $popover.closest('.product_card, .product');

    const $cartBadge = $product.find('.js-cart-counter');
    const $counterInput = $product.find('.js-counter-input');

    $popover.addClass('cart_adding__popover--show');

    $accept.on('click', (e) => {
        e.preventDefault();

        $stepOne.removeClass('cart_adding__step_one--show');
        $stepTwo.addClass('cart_adding__step_two--show');

        $cartBadge
            .addClass('cart__badge--cart_counter')
            .children('.cart__badge_count')
            .text(parseInt($counterInput.val()) + (parseInt($cartBadge.children('.cart__badge_count').text()) || 0));

        $accept.off('click');
    });

    $close.on('click', () => {
        $popover.removeClass('cart_adding__popover--show');

        $popover.on('transitionend', (e) => {
            if (e.target.classList.contains('js-cart-adding-popover')) {
                $stepOne.addClass('cart_adding__step_one--show');
                $stepTwo.removeClass('cart_adding__step_two--show');

                $popover.off('transitionend');
            }
        });

        $close.off('click');
        libNodes.$document.off('.cartAdding');
    });

    // Закрытие по клику вне поповера
    libNodes.$document.on('click.cartAdding', (e) => {
        // Если клик не по врапперу с поповером и кнопкой
        if (!$wrapper.has(e.target).length) {
            $close.trigger('click');
        }
    });
});

export default init();
