import libNodes from './LibNodes';

/**
 * Функция скроллит страницу до указанного элемента
 *
 * @param {HTMLElement} element Элемент, до которого необходимо проскроллить страницу
 */
const scrollToElement = (element) => {
    libNodes.$html.addClass('scroll_animated');

    libNodes.$htmlBody.stop().animate(
        {
            scrollTop: $(element).offset().top
        },
        800,
        'swing',
        () => libNodes.$html.removeClass('scroll_animated')
    );
};

export default scrollToElement;
