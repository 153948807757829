import 'selectize';

const init = () => {
    $('.select').each(function() {
        $(this).selectize({
            allowEmptyOption: true,
            openOnFocus: false,
            ignoreFocusOpen: true,
            plugins: this.multiple ? ['remove_button'] : [],
            maxItems: this.multiple ? null : 1,

            onInitialize: function() {
                const self = this;

                self.$wrapper.on('mousedown', function() {
                    if (!self.ignoreFocusOpen) {
                        self.open();
                        self.ignoreFocusOpen = true;
                    } else {
                        console.log('close');
                        self.close();
                        self.ignoreFocusOpen = false;
                    }
                });
            },

            onChange: function() {
                this.ignoreFocusOpen = false;
            },

            onDropdownClose: function() {
                this.ignoreFocusOpen = false;
            }
        });
    });
};

export default init();
