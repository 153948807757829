import { libNodes } from '../utils/Utils';

const numericPattern = new RegExp(/\d/);

const init = () => {
    libNodes.$body.on('click', '.js-counter-increase', function(e) {
        e.preventDefault();

        const $input = $(this).siblings('.js-counter-input');
        $input.val(parseInt($input.val()) + 1);
    });

    libNodes.$body.on('click', '.js-counter-decrease', function(e) {
        e.preventDefault();

        const $input = $(this).siblings('.js-counter-input');
        if ($input.val() > 1) {
            $input.val(parseInt($input.val()) - 1);
        }
    });

    libNodes.$body.on('keydown', '.js-counter-input', function(e) {
        if (!numericPattern.test(e.key) && e.key.length === 1) {
            e.preventDefault();
        }
    });

    libNodes.$body.on('change', '.js-counter-input', function(e) {
        const $input = $(this);

        if ($input.val() === '' || $input.val() < 1) {
            $input.val(1);
        }
    });
};

export default init();
