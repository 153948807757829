/**
 * Функция определяет ширину полосы прокрутки (скроллбара)
 *
 * @returns {Number} Ширина полосы прокрутки
 */
const getScrollbarWidth = () => {
    const div = document.createElement('div');

    div.style.width = '50px';
    div.style.height = '50px';
    div.style.overflowY = 'scroll';
    div.style.visibility = 'hidden';
    div.style.position = 'absolute';

    document.body.appendChild(div);

    const scrollbarWidth = div.offsetWidth - div.clientWidth;

    document.body.removeChild(div);

    return scrollbarWidth;
};

export default getScrollbarWidth;
