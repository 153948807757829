import autosize from 'autosize';

const $inputsInForm = $('.form_wrapper .input, .textarea');

const init = () => {
    $inputsInForm.on('change', function() {
        this.value
            ? $(this)
                  .closest('.form__group')
                  .addClass('form__group--not_empty')
            : $(this)
                  .closest('.form__group')
                  .removeClass('form__group--not_empty');
    });

    autosize($('.textarea'));
};

export default init();
