/**
 * Часто используемые jQuery элементы
 * @type {{$html: (jQuery), $htmlBody: (jQuery)}}
 */
const libNodes = {
    $document: $(document),
    $window: $(window),
    $html: $('html'),
    $body: $('body'),
    $htmlBody: $('html, body'),
    $header: $('.header')
};

export default libNodes;
