/**
 * Функция проверяет, попадает ли переданный элемент во вьюпорт пользователя
 *
 * @param {HTMLElement} element Элемент, для которого необходимо определить попадание во вьюпорт
 * @returns {Boolean} True, если элемент полностью попадает во вьюпорт
 */
const isInViewPort = (element) => {
    const position = element.getBoundingClientRect();

    return position.top >= 0 && position.top + element.offsetHeight <= window.innerHeight;
};

export default isInViewPort;
